import React from 'react';

const Home = () => (
  <div className="bg-gray-100 min-h-screen">
    {/* Hero Section */}
    <div className="relative bg-cover bg-center h-screen" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1517433456452-f9633a875f6f?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="container mx-auto flex flex-col items-center justify-center h-full relative z-10 text-white text-center p-4">
        <h1 className="text-5xl md:text-6xl font-bold mb-4">Welcome to Purple Code Consulting</h1>
        <p className="text-lg md:text-xl mb-8">Helping individuals and small companies establish an online presence through stunning websites.</p>
        <button className="btn-primary px-6 py-3 text-lg rounded">Get Started</button>
      </div>
    </div>

    {/* Services Section */}
    <div className="container mx-auto py-20 px-4 text-center">
      <h2 className="text-3xl md:text-4xl font-bold mb-8 text-purple-600">Our Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">Web Design</h3>
          <p className="text-gray-700">Creating beautiful and functional websites tailored to your needs.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">SEO Optimization</h3>
          <p className="text-gray-700">Improving your website's visibility on search engines.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">E-commerce Solutions</h3>
          <p className="text-gray-700">Building online stores that drive sales and growth.</p>
        </div>
      </div>
    </div>

    {/* Call to Action Section */}
    <div className="bg-purple-600 py-20 text-center text-white">
      <h2 className="text-3xl md:text-4xl font-bold mb-4">Ready to take your online presence to the next level?</h2>
      <p className="text-lg md:text-xl mb-8">Contact us today to get started on your project.</p>
      <button className="btn-primary px-6 py-3 text-lg rounded">Contact Us</button>
    </div>
  </div>
);

export default Home;
