import React from 'react';

const Services = () => (
  <div className="bg-gray-100 min-h-screen p-8">
    <div className="container mx-auto text-center py-20">
      <h1 className="text-4xl font-bold text-purple-600">Our Services</h1>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-white p-6 shadow-lg rounded">
          <h2 className="text-2xl font-semibold">Web Design</h2>
          <p className="mt-2 text-gray-700">Beautiful and responsive web designs to make your brand stand out.</p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded">
          <h2 className="text-2xl font-semibold">E-commerce Solutions</h2>
          <p className="mt-2 text-gray-700">Secure and scalable e-commerce solutions for your online store.</p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded">
          <h2 className="text-2xl font-semibold">SEO Optimization</h2>
          <p className="mt-2 text-gray-700">Improve your website's visibility on search engines with our SEO services.</p>
        </div>
      </div>
    </div>
  </div>
);

export default Services;
