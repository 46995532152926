import React from 'react';

const Contact = () => (
  <div className="bg-gray-100 min-h-screen p-8">
    <div className="container mx-auto text-center py-20">
      <h1 className="text-4xl font-bold text-purple-600">Contact Us</h1>
      <form className="mt-8 max-w-md mx-auto bg-white p-6 shadow-lg rounded">
        <div className="mb-4">
          <label className="block text-gray-700">Name</label>
          <input type="text" className="w-full px-4 py-2 border border-gray-300 rounded" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input type="email" className="w-full px-4 py-2 border border-gray-300 rounded" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Message</label>
          <textarea className="w-full px-4 py-2 border border-gray-300 rounded" rows="4"></textarea>
        </div>
        <button type="submit" className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">Send</button>
      </form>
    </div>
  </div>
);

export default Contact;
