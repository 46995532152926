import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar p-4 flex items-center justify-between">
      <Link to="/" className="text-2xl font-bold">Purple Code Consulting</Link>
      <button className="md:hidden" onClick={toggleMenu}>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
      <div className={`md:flex space-x-4 ${isOpen ? 'block' : 'hidden'}`}>
        <Link to="/" className="block md:inline-block mt-4 md:mt-0 hover:text-gray-300">Home</Link>
        <Link to="/services" className="block md:inline-block mt-4 md:mt-0 hover:text-gray-300">Services</Link>
        <Link to="/about" className="block md:inline-block mt-4 md:mt-0 hover:text-gray-300">About</Link>
        <Link to="/contact" className="block md:inline-block mt-4 md:mt-0 hover:text-gray-300">Contact</Link>
      </div>
    </nav>
  );
};

export default Navbar;
