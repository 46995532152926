import React from 'react';

const About = () => (
  <div className="bg-gray-100 min-h-screen p-8">
    <div className="container mx-auto text-center py-20">
      <h1 className="text-4xl font-bold text-purple-600">About Us</h1>
      <p className="mt-4 text-lg text-gray-700">Purple Code Consulting is dedicated to helping businesses establish a strong online presence through professional and affordable web solutions.</p>
    </div>
  </div>
);

export default About;
