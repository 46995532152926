import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/services"  element={<Services />}/>
        <Route path="/about"  element={<About />}/>
        <Route path="/contact"  element={<Contact />}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
